export const cookiePage: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  cookieContent: "Cookie Policy - Retina Brazil (PT)",
  banner: "Banner - HTML - Cookie Page - DTC - Brazil (PT)",
  footerClassName: "common-footer"
}
